import contentfulClient from './createContentfulClient';

function getFieldsData(data) {
  if (Array.isArray(data)) {
    return data.map(getFieldsData);
  }

  if (typeof data === 'object' && data !== null) {
    if (data.fields) {
      const fieldsWithId = {
        ...data.fields,
        id: data.sys.id,
        contentType: data.sys.contentType
      };
      return getFieldsData(fieldsWithId);
    } else {
      const result = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          result[key] = getFieldsData(data[key]);
        }
      }
      return result;
    }
  }
  return data;
}

const getPageDataByPath = async path => {
  let response = [];
  if (path.endsWith('.html') || path.endsWith('.html/')) {
    path = path.replace(/\/$/, '');
  }
  else if(!path.endsWith('/')) {
    path += '/';
  }
  try {
    const response = await contentfulClient.getEntries({
      'fields.path': path,
      content_type: 'pageNZ',
      include: 4,
      // select: 'fields'
    });
    return getFieldsData(response.items);
  } catch (error) {
    console.log('Error fetching contentful entries', error);
    return response;
  }
};

export { getPageDataByPath };
