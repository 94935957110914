import React, { useState, useEffect, createContext } from 'react';
import { getPageDataByPath } from '@/utils/getContentfulData';
import { Loading } from '../components/Loading/Loading';
import { Box } from '../components/Box/Box';

const PageContext = createContext();

const PageContextProvider = ({ children }) => {
  const [pageData, setPageData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (typeof window !== 'undefined') {
        const response = await getPageDataByPath(location.pathname);

        if (response && response.length > 0) {
          setPageData(response[0]);
        }
      }
    };
    Promise.resolve(fetchData()).then(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Box className="d-flex align-items-center" width="100vw" height="100vh">
        <Loading />
      </Box>
    )
  }

  return (
    <PageContext.Provider value={pageData}>
      {children}
    </PageContext.Provider>
  );
};

export { PageContextProvider as default, PageContext };
